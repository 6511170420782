export const GET_PATIENT_PROFILE_DATA = 'GET_PATIENT_PROFILE_DATA';
export const getPatientProfileData = payload => ({ type: GET_PATIENT_PROFILE_DATA, payload });

export const SET_PATIENT_PROFILE_DATA = 'SET_PATIENT_PROFILE_DATA';
export const setPatientProfileData = payload => ({ type: SET_PATIENT_PROFILE_DATA, payload });

export const UPDATE_PATIENT_PROFILE_DATA = 'UPDATE_PATIENT_PROFILE_DATA';
export const updatePatientProfileData = payload => ({ type: UPDATE_PATIENT_PROFILE_DATA, payload });

export const SET_UPDATE_PATIENT_PROFILE_DATA = 'SET_UPDATE_PATIENT_PROFILE_DATA';
export const setUpadatedPatientProfileData = payload => ({ type: SET_UPDATE_PATIENT_PROFILE_DATA, payload });

export const GET_DENTIST_LIST_DATA = 'GET_DENTIST_LIST_DATA';
export const getDentistListData = payload => ({ type: GET_DENTIST_LIST_DATA, payload });

export const SET_DENTIST_LIST_DATA = 'SET_DENTIST_LIST_DATA';
export const setDentistListData = payload => ({ type: SET_DENTIST_LIST_DATA, payload });